//colors
$primary-color: #113f67;
$secondary-color: #f3f9fb;
$border-inputs: rgba(223, 221, 221, 0.8);
$header-color: #ff6d25;

@import url('https://fonts.googleapis.com/css2?family=Roboto+Mono:wght@500;600&display=swap');

textarea:focus, input:focus{
  outline: none;
}

button:focus {
  outline:0 !important;
}

/*
.fhaVWy { //datable height principal dev.
  //min-height: 200px !important;
}
.kvHQHO { //datable height principal prod.
  //min-height: 200px !important;
}
*/

.observation-detail {
  background-color: #f3f9fb;
  padding: 10px;
  margin: 5px 0px;
  border-radius: 5px;
}

.content-anulation {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    font-size: 13px;
    border: 2px dotted;
    border-radius: 10px;
    padding: 5px;
}

.box-option-reports:hover {
  background-color: #ff6d25;
  color: white;
  cursor: pointer;
  text-decoration: none;
}

.box-option-reports {
  background-color: #ffffff;
  border-radius: 10px;
  padding: 20px;
  justify-content: center;
  display: flex;
  align-items: center;
  box-shadow: 1px 0px 20px rgba(0, 0, 0, 0.08);
  -webkit-box-shadow: 1px 0px 20px rgba(0, 0, 0, 0.08);
  -moz-box-shadow: 1px 0px 20px rgba(0, 0, 0, 0.08);
  -o-box-shadow: 1px 0px 20px rgba(0, 0, 0, 0.08);
  margin-top: 10px;
}

.box-option-reports > h1 {
  font-size: 1.2rem;
}

.big-text {
  font-size: 25px;
  line-height: 1;
  font-weight: bold;
}

.title-amount {
  font-size: 18px;
}

.title-mediun {
  font-weight: bold;
  font-size: 17px;
}

.tag-anulado {
  background-color: #FF3B3B;
  padding: 6px;
  border-radius: 8px;
  color: white;
  font-weight: bold;
  text-align: center;
  font-size: 20px;
}

.navActive {
  border-right: 5px solid #ff6d25;
  background: #FFEBE1;
}

.non-products {
  min-height: 100px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.alertPay {
  background-color: red;
  padding: 8px;
  font-size: 12px;
  border-radius: 10px;
  color: white;
}

.dbxAlertUser { 
  min-height: 30px;
  align-items: center;
  display: flex;
  justify-content: center;
  margin-top: 15px;
}

.alert-anular {
  background-color: rgb(252, 240, 134);
  padding: 5px;
  border-radius: 5px;
  margin-top: 5px;
  border: 1px solid #ffe23b
}

.loader__wrapper {
  position: absolute;
  height: 100%;
  width: 100%;
  background-color: rgba($color: #fff, $alpha: 0.5);
  z-index: 999;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: all 0.3s ease-out;
  backdrop-filter: blur(1px);
}

.lds-ring {
  display: inline-block;
  position: relative;
  width: 80px;
  height: 80px;
}

.loader-spin {
  border: 8px solid #f3f3f3; /* Light grey */
  border-top: 8px solid $primary-color; /* Blue */
  border-radius: 50%;
  width: 50px;
  height: 50px;
  animation: spin 1.0s linear infinite;
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}


.fade-in {
  animation: fadeIn ease 0.2s;
  -webkit-animation: fadeIn ease 0.2s;
  -moz-animation: fadeIn ease 0.2s;
  -o-animation: fadeIn ease 0.2s;
  -ms-animation: fadeIn ease 0.2s;
  }
  @keyframes fadeIn {
  0% {opacity:0;}
  100% {opacity:1;}
  }
  
  @-moz-keyframes fadeIn {
  0% {opacity:0;}
  100% {opacity:1;}
  }
  
  @-webkit-keyframes fadeIn {
  0% {opacity:0;}
  100% {opacity:1;}
  }
  
  @-o-keyframes fadeIn {
  0% {opacity:0;}
  100% {opacity:1;}
  }
  
  @-ms-keyframes fadeIn {
  0% {opacity:0;}
  100% {opacity:1;}
  }

.login__Container {
  background-color: $secondary-color;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.login__logo {
  width: 100px;
  height: 100px;
  background-color: red;
}

.login__Box {
  width: 450px;
  height: 300px;
  background-color: #fff;
  border: 0;
  border-radius: 2px;
  box-shadow: 0px 1px 2px 0px rgba(9, 30, 66, 0.25);
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.login__inputContainer {
  width: 100%;
}

.login_inputtText
{
  font-size: 12px !important;
  height: 45px !important;
}

.login_textInput {
  width: 100%;
  text-align: center;
  padding: 10px;
}

.login__inputContainer {
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.login_buttonLogin {
  width: 100%;
  background-color: red;
}

.login__message {
  margin-top: 10px;
  min-height: 50px;
  font-size: 12px;
}

.btn_primary {
  display: block;
  width: 100%;
  border: 0;
  height: 45px;
  background-color: $primary-color;
  color: white;
  font-weight: bold;
  outline:0;
}

.logo-size {
  width: 40%;
}

.headerTop {
  position: fixed;
  z-index: 50;
  width: 100%;
  background: #fff;
  height: 48px;
  background: $header-color;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-right: 20px;
 }

 .navbar-header {
   background: #fff;
   width: 210px;
   height: 100%;
   border: 1px solid $border-inputs;
   display: flex;
   align-items: center;
   padding: 10px;
   justify-content: center;
 }

 .headerName {
  display: flex;
  align-items: center;
  justify-content: flex-end;
 }

 .headerBrand {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  font-weight: bold;
 }

 .left-sidebar {
  position: fixed;
  width: 210px;
  height: 100%;
  top: 0;
  z-index: 10;
  padding-top: 64px;
  background: #fff;
  box-shadow: 1px 0 20px rgba(0,0,0,.08);
  transition: .2s ease-in;
  overflow-x: scroll;
 }

 .scrollbar-container {
   position: relative;
   height: 100%;
 }

 .nv {
  display: flex;
  flex-wrap: wrap;
  padding-left: 0;
  margin-bottom: 0;
  list-style: none;
 }

 .sidebar-nav ul li {
   list-style: none;
 }

 .sidebar-link {
  color: #000;
  padding: 4px 8px;
  display: flex;
  white-space: nowrap;
  align-items: center;
  line-height: 25px;
  font-size: 11px;
  cursor: pointer;
  transition: all .3s ease;
    &:hover {
      text-decoration: none;
      color: #131416;
      background: #FFEBE1;
    }
 }

 .icon-nav {
   width: 35px;
   height: 35px;
 }

 .icon-nav-dashboard {
  width: 75px;
  height: 75px;
 }

 .sidebar-item {
   width: 100%;
 }

 .icon-nav {
    font-style: normal;
    width: 35px;
    line-height: 25px;
    font-size: 20px;
    color: #212529;
    display: inline-block;
    text-align: center;
    margin-right: 10px;
 }

.content-general {
  margin-left: 213px;
  padding: 65px 18px 30px 14px;
}

.card-box {
    position: relative;
    display: flex;
    flex-direction: column;
    min-width: 0;
    word-wrap: break-word;
    background-color: #fff;
    background-clip: border-box;
    border: 0 solid transparent;
    border-radius: 4px;
    box-shadow: 1px 0px 20px rgba(0, 0, 0, 0.08);
    -webkit-box-shadow: 1px 0px 20px rgba(0, 0, 0, 0.08);
    -moz-box-shadow: 1px 0px 20px rgba(0, 0, 0, 0.08);
    -o-box-shadow: 1px 0px 20px rgba(0, 0, 0, 0.08);
}

.title-card-box {
  font-weight: 'bold';
  font-size: 17px;
}

.btnx-warn {
    color: #fff;
    background-color: $header-color;
    border-color: $header-color;
    box-shadow: 0 4px 6px rgba(50,50,93,.11), 0 1px 3px rgba(0,0,0,.08);
    //min-width: 43px;
}

.btnx {
    display: inline-block;
    font-weight: 400;
    text-align: center;
    white-space: nowrap;
    vertical-align: middle;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    border: 1px solid transparent;
    padding: .375rem .75rem;
    font-size: .875rem;
    line-height: 1.5;
    border-radius: 2px;
    transition: color .15s ease-in-out,background-color .15s ease-in-out,border-color .15s ease-in-out,box-shadow .15s ease-in-out;

    &:hover {
      background-color: #eb5e17
    }
}

.icon-table {
  margin-right: 3px;
}

.badge-npx-danger {
  color: #fff;
  background-color: #f62d51;
}

.badge-npx-warning {
  color: #fff;
  background-color: #fb6340;
}

.badge-npx {
    display: inline-block;
    padding: .35em .7em;
    font-size: 73%;
    line-height: 1;
    text-align: center;
    white-space: nowrap;
    vertical-align: baseline;
    border-radius: 2px;
}

.ripple {
  background-position: center;
  transition: background 0.8s;
}
.ripple:hover {
  background: #226597 radial-gradient(circle, transparent 1%, #226597 1%) center/15000%;
}
.ripple:active {
  background-color: #6eb9f7;
  background-size: 100%;
  transition: background 0s;
}

.container_menu_option {
  display: flex;
  flex-direction: row;
}

.menu_option {
  min-width: 100px;
  text-align: center;
}

.menu_option > a {
  color: white;
}

.menu_option > a:hover {
    font-weight: bold;
    color: white;
    text-decoration: none;
}

.active-menu {
  background-color: #113f67;
  border-radius: 5px;
  font-weight: bold;
}

.input-border {
  border: 1px solid $border-inputs !important;
}

.editorClassName {
  border: 1px solid $border-inputs;
  padding: 10px;
  height: 350px !important;
}

.toolbarClassName {
  border: 1px solid $border-inputs !important;
}

.grupo-input-radio {
  display: flex;
  flex-direction: row;
  height: 40px;
  align-items: center;
}
.radio-input {
  display: flex;
  align-items: center;
  margin-right: 10px;
}
.label-radio {
  margin-right: 10px;
}

.gallery-product {
  border: 1px solid #dfddddcc;
  height: 150px;
  border-radius: 5px;
  margin-top: 10px;
  padding: 10px 10px 10px 0px;
  display: flex;
  flex-wrap: nowrap;
  overflow-x: auto;
}

.image-boxes {
  border: 1px solid #dfddddcc;
  height: 130px;
  width: 150px;
  border-radius: 5px;
  margin-left: 10px;
  flex: 0 0 auto;
}

.photo-selected {
  padding: 20px;
  align-items: center;
  justify-content: center;
  display: flex;
  cursor: pointer;
}

.photo-selected > img {
  width: 50%;
}

.img-fluid-prop {
  width: 100%;
  height: 100%;
}

.disabled-modal {
    position: absolute;
    width: 100%;
    height: 100%;
    background: black;
    top: 0;
    left: 0;
    border-radius: 3px;
    z-index: 2;
    opacity: 0.3;
}

.backdrop-gallery {
  position: absolute;
  width: 97%;
  height: 93%;
  top: 0;
  background-color: rgba(0, 0, 0, 0.8);
  margin-top: 11px;
  border-radius: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
}

.ModalCustom {
  position: fixed;
  z-index: 500;
  //height: calc(100vh - 200px);
  //padding: 16px;
  box-sizing: border-box;
  transition: all 0.3s ease-out;
  
}

.modal-xs {
  width: 30%;
  left: 35%;
  top: 25%;
}

.modal-lg {
  width: 60%;
  top: 5%;
  left: 28%;
}

.modal-xl {
  width: 70%;
  top: 5%;
  left: 22%;
}  


.Backdrop {
  width: 100%;
  height: 100%;
  position: fixed;
  z-index: 100;
  left: 0;
  top: 0;
  background-color: rgba(0,0,0,0.5);
}

.title-card {
  font-size: 18px;
}

.bultosText {
  display: flex;
  align-items: flex-end;
  font-weight: bold;
  justify-content: flex-end;
}

.botonGenerar {
  display: flex;
  align-items: flex-end;
  font-weight: bold;
}

.box-master-guias {
  height: 150px;
  overflow-y: scroll;
}

.item-master-guia {
  border-bottom: 1px solid #ccc;
  padding: 10px 0px 10px 0px;
}

.guia-status-box {
  font-size: 12px;
  padding: 10;
  border-bottom: 1px solid #ccc;
  height: 45px;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

button:disabled,
button[disabled]{
  background-color: #cccccc;
  color: #666666;
}

button:hover {
  color: #ffffff;
}

.card-box-text {
  height: 560px !important;
}

.editor-class {
  padding: 10px;
  height: 100% !important;
  background-color: #f8f8f8;
}

.wrapper-class-x {
  height: 400px !important;
}

.brand-login {
  border-right: 1px solid $border-inputs;
  height: 100vh;
  background-color: $header-color;
  box-shadow: 1px 0px 20px rgba(0, 0, 0, 0.08);
  -webkit-box-shadow: 1px 0px 20px rgba(0, 0, 0, 0.08);
  -moz-box-shadow: 1px 0px 20px rgba(0, 0, 0, 0.08);
  -o-box-shadow: 1px 0px 20px rgba(0, 0, 0, 0.08);
}

.login-container {
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 100%;
  background: white;
}

.grupo-input-login {
  width: 70%;
  margin-bottom: 10px;
}

.grupo-input-login > input {
  height: 50px;
  font-size: 13px;
}

.grupo-input-login > small {
  font-size: 14px;
  font-weight: bold;
}

.author-login {
  position: absolute;
  bottom: 10px;
  font-size: 10px;
}
.author-login > span {
  font-weight: 900;
  font-size: 11px;
}

.logo-position {
  padding: 15%;
  width: 80%;
}

.bordernav {
  //border-bottom: 1px solid #ccc;
  margin: 0px 10px 0px 10px;
}

.fail-login {
   min-height: 30px;
   font-size: 11px;
   background-color: #f62d51;
   padding: 8px;
   align-items: center;
   justify-content: center;
   color: white;
   box-shadow: 1px 0px 20px rgba(0, 0, 0, 0.08);
   -webkit-box-shadow: 1px 0px 20px rgba(0, 0, 0, 0.08);
   -moz-box-shadow: 1px 0px 20px rgba(0, 0, 0, 0.08);
   -o-box-shadow: 1px 0px 20px rgba(0, 0, 0, 0.08);
   width: 80%;
   text-align: center;
}

.name-company {
  color: #000;
  padding: 12px 15px;
  display: flex;
  white-space: nowrap;
  align-items: center;
  line-height: 25px;
  font-size: 14px;
  font-weight: bold;
  padding-left: 26px;
}

.backgroud-brand {
  background-image: url('../images/general_3.png');
  background-size: contain;
  background-repeat: no-repeat;
  background-position: bottom right;
}

.text-login-brand {
  padding: 20px 0px 0px 20px;
}

.text-login-brand h1 {
  font-weight: bold;
  color: #fff;
  margin: 0;
  font-size: 3.5rem;
}

.text-login-brand span {
  font-weight: bold;
  color: #fff;
  margin: 0;
  font-size: 1.5rem;
}

.frame-image-email {
  border: 1px solid $border-inputs;
  width: 500px;
  height: auto;
  border-radius: 5px;
  padding: 10px;
}

.frame-image-guia {
  border: 1px solid $border-inputs;
  width: 300px;
  height: auto;
  border-radius: 5px;
}

.warning-card {
  background-color: rgb(223, 0, 0);
  font-size: 15px;
}

.home-text {
  text-align: right;
}

.home-text h1 {
  font-size: 22px;
  font-weight: 700;
}

.preloader-grafico {
  background-color: rgba(0, 0, 0, 0.08);
  position: absolute;
  width: 100%;
  height: 100%;
  z-index: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: bold;
}

.boton-end {
  display: flex;
  align-items: flex-end;
  margin-bottom: 2px;
}

.sync-grafico {
  position: absolute;
  bottom: 0;
  right: 0;
}

.home-text p {
  margin: 0;
  font-size: 25px;
  margin: 0;
}

.home-icon {
  font-size: 3.5rem;
  color: $header-color;
}

.spinner-wrapper {
  width: 100vw;
  height: 100vh;
  align-items: center;
  justify-content: center;
  display: flex;
}

.grupinput-two {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.loader-window {
  width: 100%;
  min-height: 120px;
  align-items: center;
  justify-content: center;
  display: flex;
}

.frame-transfer {
  background-color: rgb(243, 243, 243);
  height: calc(100vh - 200px);
  border: 1px solid $border-inputs;
  width: 100%;
  overflow-y: scroll;
}

.border-status {
  border-bottom: 1px dotted #cecece;
  padding-bottom: 10px;
  margin-bottom: 10px;
}

.box-info-bultos {
   padding-right: 20px;
}

.scroll-status {
  overflow-x: auto;
}

.tab-button-title {
  min-width: 100px;
  border: 2px solid $header-color;
  border-radius: 15px;
  height: 40px;
  align-items: center;
  justify-content: center;
  display: flex;
  margin-right: 10px;
  padding: 15px;
  cursor: pointer;
}

.data-table-extensions {
  padding: 1.2rem 0rem !important; 
}


.tab-button-title-active {
  background-color: $header-color; 
  font-weight: bold;
  color: black;
}

.new-closed-button {
  width: 40px;
  height: 40px;
  border-radius: 15px;
  position: absolute;
  right: 10px;
  font-weight: bold;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  color: black;
}

.new-closed-button:hover {
  color: $header-color;
}

.new-close-animation {
  transition: 0.3s all ease;
}

.notification-news-box {
  min-height: 45px;
  background-color: rgb(148, 240, 205);
  border-radius: 5px;
  padding: 10px;
  box-shadow: 1px 0px 20px rgba(0, 0, 0, 0.08);
  -webkit-box-shadow: 1px 0px 20px rgba(0, 0, 0, 0.08);
  -moz-box-shadow: 1px 0px 20px rgba(0, 0, 0, 0.08);
  -o-box-shadow: 1px 0px 20px rgba(0, 0, 0, 0.08);
  display: flex;
  flex-direction: column;
  position: relative;
}

.new-footer-alert {
  text-align: right;
  & > span {
    font-weight: bold;
    padding-right: 15px;
  }
}

.news-icon-box {
  width: 50px;
  height: 50px;
}

.access-denied-box {
  width: 50%;
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.access-denied-container {
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 300px;
 }

.denied-icon-box {
  width: 120px;
}

.denied-text {
  line-height: 2;
  & > span {
    color: $header-color;
    font-weight: bold;
  }
}

.tabs-container {
  display: flex;
  flex-direction: row;

}

.empty-status {
  height: 200px;
  align-items: center;
  justify-content: center;
  display: flex;
  width: 100%;
}

.control-transfer {
  margin: 0 10px 0 10px;
  display: flex;
  align-items: center;
  justify-items: center;
}

/* Absolute Center Spinner */
.loading {
  position: fixed;
  z-index: 999;
  height: 2em;
  width: 2em;
  overflow: show;
  margin: auto;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
}

/* Transparent Overlay */
.loading:before {
  content: '';
  display: block;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
    background: radial-gradient(rgba(20, 20, 20,.8), rgba(0, 0, 0, .8));

  background: -webkit-radial-gradient(rgba(20, 20, 20,.8), rgba(0, 0, 0,.8));
}

/* :not(:required) hides these rules from IE9 and below */
.loading:not(:required) {
  /* hide "loading..." text */
  font: 0/0 a;
  color: transparent;
  text-shadow: none;
  background-color: transparent;
  border: 0;
}

.loading:not(:required):after {
  content: '';
  display: block;
  font-size: 10px;
  width: 1em;
  height: 1em;
  margin-top: -0.5em;
  -webkit-animation: spinner 150ms infinite linear;
  -moz-animation: spinner 150ms infinite linear;
  -ms-animation: spinner 150ms infinite linear;
  -o-animation: spinner 150ms infinite linear;
  animation: spinner 150ms infinite linear;
  border-radius: 0.5em;
  -webkit-box-shadow: rgba(255,255,255, 0.75) 1.5em 0 0 0, rgba(255,255,255, 0.75) 1.1em 1.1em 0 0, rgba(255,255,255, 0.75) 0 1.5em 0 0, rgba(255,255,255, 0.75) -1.1em 1.1em 0 0, rgba(255,255,255, 0.75) -1.5em 0 0 0, rgba(255,255,255, 0.75) -1.1em -1.1em 0 0, rgba(255,255,255, 0.75) 0 -1.5em 0 0, rgba(255,255,255, 0.75) 1.1em -1.1em 0 0;
box-shadow: rgba(255,255,255, 0.75) 1.5em 0 0 0, rgba(255,255,255, 0.75) 1.1em 1.1em 0 0, rgba(255,255,255, 0.75) 0 1.5em 0 0, rgba(255,255,255, 0.75) -1.1em 1.1em 0 0, rgba(255,255,255, 0.75) -1.5em 0 0 0, rgba(255,255,255, 0.75) -1.1em -1.1em 0 0, rgba(255,255,255, 0.75) 0 -1.5em 0 0, rgba(255,255,255, 0.75) 1.1em -1.1em 0 0;
}

/* Animation */

@-webkit-keyframes spinner {
  0% {
    -webkit-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -ms-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    -moz-transform: rotate(360deg);
    -ms-transform: rotate(360deg);
    -o-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
@-moz-keyframes spinner {
  0% {
    -webkit-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -ms-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    -moz-transform: rotate(360deg);
    -ms-transform: rotate(360deg);
    -o-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
@-o-keyframes spinner {
  0% {
    -webkit-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -ms-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    -moz-transform: rotate(360deg);
    -ms-transform: rotate(360deg);
    -o-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
@keyframes spinner {
  0% {
    -webkit-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -ms-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    -moz-transform: rotate(360deg);
    -ms-transform: rotate(360deg);
    -o-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

.totales {
  font-size: 14px !important;
  font-weight: 200 !important;
}

.container-button-payment {
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
}

.button-webpay {
  display: flex;
  flex-direction: column;
}

.side-drawer { 
  height: 100%;
  background: white;
  position: fixed;
  top: 0;
  right: 0;
  width: 40%;
  z-index: 200;
  box-shadow: 1px 0px 7px rgba(0,0,0,0.5); 
  transform: translateX(100%);
  transition: transform 0.3s ease-out;
  overflow-x: scroll;
}

.side-drawer.open {
  transform: translateX(0);
}

.profile-user {
  width: 80px;
  height: 80px;
  border-radius: 50px;
  margin-right: 10px;
  background-size: cover;
}

.box-profile-container {
  padding: 10px 20px;
}

.profile-container {
  width: 50%;
  border-right: 2px solid rgb(240, 240, 240);
}

.box-info-despacho {
  width: 25%;
}

.border-info-box {
  border-right: 2px solid rgb(240, 240, 240);
}

.info-context small {
  font-weight: bold;
}

.small-profile-dbx {
  width: 35px;
  height: 35px;
  border-radius: 17.5px;
  background-color: rgb(231, 231, 231);
  margin: 0px 5px;
  background-size: cover;
  border: 1px dotted #000;
}

.delivery-name {
  text-transform: uppercase;
  font-weight: bold;
}

.not-geo {
  height: 100px;
  background: rgb(240, 240, 240);
  display: flex;
  align-items: center;
  justify-content: center;
}

.info-context div {
  line-height: 1;
  font-size: 20px;
}

.closed-modal-lateral {
    position: absolute;
    z-index: 99;
    margin: 20px 28px;
    height: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 15px;
    padding: 10px;
    background-color: #ff6d25;
    cursor: pointer;
}

.btn-close-drawer small {
  margin: 0;
  font-weight: bold;
}

.btn-close-drawer {
  margin-left: 10px;
  line-height: 1;
}

.box-image-modal-sign {
  width: 35%;
}

.box-image-modal {
  width: 35%;
}

.info-context {
  text-align: center;
}

.user-information-app h2 {
  font-size: 18px;
  text-transform: uppercase;
  font-weight: bold;
  margin: 0;
}

.data-table-extensions {
  display: inline-block !important;
  width: 100% !important;
  box-sizing: border-box !important;
}

.data-table-extensions > .data-table-extensions-filter {
  float: left !important;
  width: 100% !important;
}

.data-table-extensions > .data-table-extensions-filter > .icon {
  display: none !important;
}

.data-table-extensions > .data-table-extensions-filter > .filter-text {
  display: block;
  width: 100% !important;
  height: calc(1.5em + .75rem + 2px);
  padding: .375rem .75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  border-radius: .25rem;
  transition: border-color .15s ease-in-out,box-shadow .15s ease-in-out;
}

.data-table-extensions > .data-table-extensions-filter > .filter-text::placeholder {
  color: #ccc !important;
}

.data-table-extensions > .data-table-extensions-filter > .filter-text:focus,
.data-table-extensions > .data-table-extensions-filter > .filter-text:hover {
  border-bottom-color: #dfdfdf !important;
}

.monto-text {
  font-weight: bold;
  font-size: 20px;
}

.amount-good {
  color: #68DA61;
}

.rs-picker-has-value .rs-btn .rs-picker-toggle-value, .rs-picker-has-value .rs-picker-toggle .rs-picker-toggle-value {
  color: 'black' !important;
}

.boleta-price {
   font-size: 23px;
   font-weight: bold;
   color: rgb(104, 218, 97)
}

.label-text {
  font-weight: bold;
}

.box-title-modal { 
  padding-bottom: 10px;
  border-bottom: 1px solid #cccccc;
  margin-bottom: 10px;
}

@media print {
  .printElement {
  }
}


@keyframes lds-ring {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}


@media all {
  .page-break {
    display: none;
  }
}

@media print {
  html, body {
    height: initial !important;
    overflow: initial !important;
    -webkit-print-color-adjust: exact;
  }
}

@media print {
  .page-break {
    margin-top: 1rem;
    display: block;
    page-break-before: auto;
  }
}

@page {
  size: auto;
  margin: 20mm;
}

@media only screen and (max-width: 1440px) { 
  .modal-lg {
    width: 60%;
    top: 5%;
    left: 22%;
  }  

  .modal-xl {
    width: 70%;
    top: 5%;
    left: 16%;
  }  
}


@media only screen and (max-width: 1280px) { 
  
  .box-option-reports h1 {
    font-size: 0.8rem;
    line-height: 1;
  }
}